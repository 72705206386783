#root {
  min-height: 100vh;
}
html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}

body{
  padding: 0!important;
}

.rdrMonthAndYearWrapperCustom{
  visibility: hidden;
}

.rdrDateRangeWrapperCustom{
  width: 350px;
}